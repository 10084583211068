import React, {useEffect, useState} from 'react';
import { translate } from '../../../selectors/translations'
import { useDispatch } from 'react-redux'

import {
  getAnamnesisAnswer,
  getAnswerRiskScore,
  getSectionData,
} from '../../../selectors/patientProfile';

import { Answer, Survey, AnamnesisSurveyConfig, AnamnesisSectionData, AnamnesisRiskIntervals } from '../../../store/PatientProfile/types';
import Toogleable from '../../../rc-components/Toggleable';
import { DropdownCb, AnamnesisConfig } from '../../../types/Common';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/information-outline.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/en-flag.svg';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/check.svg';
import { ReactComponent as WarningIcon } from '../../../assets/img/icons/warning-triangle-yellow.svg';
import { formatDate } from '../../../utils/datetime';
import { setTestAsViewed } from '../../../store/PatientProfile/action';
import {STATUS_COLOR} from '../../../types/constants';
import useTranslate from "../../../hooks/useTranslate"

type SectionInfoState = {
  survey: Survey;
  config: AnamnesisSurveyConfig;
  isNewAnamnesis: boolean;
}

type SectionTitleProps = {
  title: string;
  status: string;
  date: string;
  onClick: () => void;
  riskInfo?: any;
  showRiskWarning?: boolean;
}

type RiskIndicatorProps = {
  totalPoints: number;
  actualPoints: number;
  riskLevel: string;
  showRiskWarning?: boolean;
  forPdf?: boolean;
}

type AnswerContentProps = {
  question: any;
  answer: Answer;
  questionNumber: number;
  questionKey: string;
  isEditable?: boolean;
  surveyId: string;
  isNewAnamnesis: boolean;
}

type AnamnesisFlexAnswersProps = {
  section: AnamnesisSectionData;
  survey: Survey;
  questions: { [key: number]: AnamnesisConfig };
  isNewAnamnesis: boolean
}

type AnamnesisSectionNoteProps = {
  sectionId?: number;
  introKey?: string;
  descriptionKey: string;
  showSuicideRiskWarning?: boolean;
  intervals: {[key: string]: AnamnesisRiskIntervals[]};
}

const DEFAULT_ANSWER_DATA: Partial<Answer> = {
  corrections: [],
  data: 0,
  grade: 0,
  isRead: false,
  result: void 0,
  timeFromTouch: void 0,
  _id: ''
}

const answerDataToType: { [key: number]: (key: string) => Answer } = {
  1: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, type: 1, key } as Answer),
  2: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 2, data: '' } as Answer),
  3: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 3 }) as Answer,
  4: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 4, data: [0] } as Answer),
  5: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 5, data: '' }) as Answer,
  6: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 6, data: [0] } as Answer),
  7: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 7, data: [0] } as Answer),
  11: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 11, data: 1 } as Answer),
  12: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 12, data: [0] } as Answer),
  13: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 13, data: [0] } as Answer),
  101: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 101, data: [0, 0] } as Answer),
  103: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 103, data: [0, 0, 0] } as Answer),
  104: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 104, data: [0] } as Answer),
  105: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 105, data: [0] } as Answer),
  106: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 106, data: [0] } as Answer),
  107: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 107, data: [0] } as Answer),
  117: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 117, data: [0] } as Answer),
  108: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 108, data: [0] } as Answer),
  110: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 109, data: [0, 0, 0] } as Answer),
  111: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 111, data: [0] } as Answer),
  112: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 112, data: [0] } as Answer),
  113: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 113, data: [0] } as Answer),
  114: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 114, data: [0] } as Answer),
  115: (key: string): Answer => ({ ...DEFAULT_ANSWER_DATA, key, type: 115, data: [0] } as Answer),
}

// 1 - boolean,
// 2 - string,
// 3 - ddb,
// 5 - short string,
// 6 - boolean+string,
// 7 - boolean+tri,
// 11 - alco(new) - number,
// 101 - blood,
// 103 - alco
// 104 - yn-cb-text,
// 105 - yn-cb-cb,
// 106 - yn-ddb-ddb,
// 107 - yn-cb,
// 108 - y/n-ddb,
// 110 - three-radio-button [1, 2, 3],
// 111 - yn-ddb-text

export const SectionRiskIndicator: React.SFC<RiskIndicatorProps> = (props: RiskIndicatorProps) => {
  const translateLocalazy = useTranslate()
  const { totalPoints, actualPoints, riskLevel, forPdf, showRiskWarning = false } = props;
  const riskLevelTranslation = riskLevel.includes('.') ?  translateLocalazy(riskLevel) : translate(riskLevel)

  return (
    <div className={`flex-item--shrink text-value-risk-score p-relative ${showRiskWarning && 'pl-30'} ${forPdf && 'risk-for-pdf'}`}>
      <span className="common-title">{translate('riskIndicator')}: &nbsp;</span>
      <span className={`risk-indicator ${riskLevel}`}>{riskLevelTranslation}</span>&nbsp;
      <span>(<strong>{actualPoints}</strong> / {totalPoints})</span>
      { showRiskWarning && <div className="risk-warning-mark p-absolute"><WarningIcon /></div> }

    </div>
  )
}

type SectionNoteIntervalProps = {
  intervalsType?: string;
  intervalsData: AnamnesisRiskIntervals[];
}

export const SectionNoteInterval: React.FC<SectionNoteIntervalProps> = (props: SectionNoteIntervalProps): React.ReactElement => {
  const { intervalsData, intervalsType } = props;
  const translateLocalazy = useTranslate()

  return <dd>
    <div className="flex risk-indicator-wrapper">
      {
        intervalsType &&
        <div className="risk-indicator">
          <div className="risk-interval">{translate(intervalsType)}</div>
        </div>
      }
      {
        intervalsData.map((interval: AnamnesisRiskIntervals, i: number) => {
          const riskLevelTranslation = interval.titleKey.includes('.') ?  translateLocalazy(interval.titleKey) : translate(interval.titleKey)

          return <div key={i} className="risk-indicator">
              <div className="risk-interval">{interval.range}</div>
              <div>{riskLevelTranslation}</div>
            </div>
          }
        )
      }
    </div>
  </dd>
}

export const SectionNote: React.SFC<AnamnesisSectionNoteProps> = (props: AnamnesisSectionNoteProps): React.ReactElement => {
  const { descriptionKey, introKey, intervals } = props;
  const translateLocalazy = useTranslate()
  const description = descriptionKey?.includes('.') ? translateLocalazy(descriptionKey) : translate(descriptionKey)

  return (
    <div className="spoiler_body">
      <div className="diagnostic_question_body p-10">
        <div className="flex">
          <div className="inner-wrapper inner-wrapper--answers flex-item--stretch">
            <article className="diagnostic_question_details flex flex--wrap">
              {
                descriptionKey &&
                  <dl className="risk-notes">
                    {
                      introKey && <div>
                        {translateLocalazy(introKey)}
                            <br/>
                            <br/>
                        </div>
                    }
                      <dt><strong>{translate('note')}:</strong></dt>
                      <dd>
                          <span dangerouslySetInnerHTML={{__html: description}}></span>
                      </dd>
                  </dl>
              }
              {
                intervals &&
                <dl className={descriptionKey === 'alcoholNoteRisk' ? 'risk-notes score laila-case' : 'risk-notes score'}>
                  <dt>
                    <strong>{translate('riskInterval')}:</strong>
                  </dt>
                  {
                    intervals && Object.keys(intervals).map((intervalType: string, i: number) =>
                      <SectionNoteInterval key={i} intervalsData={intervals[intervalType]} intervalsType={['male', 'female'].includes(intervalType) ? intervalType : undefined} />)
                  }
                </dl>
              }
            </article>
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionTitle: React.SFC<SectionTitleProps> = (props: SectionTitleProps) => {
  const { title, onClick, riskInfo, status, date, showRiskWarning = false } = props;
  return (
    <div className="spoiler_header diagnostic_question_header" style={{padding: '8px 24px'}} onClick={onClick}>
      <div className="flex-container align-center flex--nowrap">
        <div className="flex anamnesis-section-title">
          <div className="flex-item--shrink fixed-title">
            <h3>{title}</h3>
          </div>
          {
            riskInfo &&
            !['Not Requested', 'Requested'].includes(status) &&
            <SectionRiskIndicator
                showRiskWarning={showRiskWarning}
                totalPoints={riskInfo.totalPoints}
                actualPoints={riskInfo.actualPoints}
                riskLevel={riskInfo.textValue} />
          }
        </div>
        <div className={`status_box flex-item--shrink`}>
          <div className="status-holder">
            {
              status === 'Complete' &&
              <CheckIcon className="circle-checked"/>
            }
            <span className="status-text">{status === 'Complete' ? formatDate(date) : translate(status)}</span>
            <span className={`circle circle--${STATUS_COLOR[status]}`}></span>
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionAnswersContent: React.FC<AnswerContentProps> = (props: AnswerContentProps) => {
  const { questionNumber, answer, question, questionKey, isNewAnamnesis } = props;
  const answerDataToPass = answer || answerDataToType[question.type](questionKey);
  const riskScores = getAnswerRiskScore(answerDataToPass, isNewAnamnesis);
  return (
    <div className="spoiler_body">
      <div className="diagnostic_question_body p-10">
        <div className="block-title flex justify-between align-start">
          <div className="flex-item--stretch flex align-center">
            <h3
              dangerouslySetInnerHTML={{ __html: `${questionNumber ? questionNumber + '.' : ''} ${question.labels[0].replace('|*', '<i>').replace('*|', '</i>')}` }}>
            </h3>
            {
              question.hint
              && <div className="question-hint">
                <InfoIcon className="info-icon" />
                <div dangerouslySetInnerHTML={{ __html: question.hint }} className="info_list"></div>
              </div>
            }
          </div>
          {
            riskScores
            && <div className="flex-item--shrink" style={{ marginLeft: '18px' }}>
              <span>{translate('Points')} <strong>{riskScores.actualScore}</strong> / {riskScores.maxScore}</span>
            </div>
          }
        </div>
        <div className="flex">
          <div className="inner-wrapper inner-wrapper--answers flex-item--stretch">
            <article className="diagnostic_question_details flex flex--wrap">
              {
                getAnamnesisAnswer(question, answer).map((t: any, i: number) => (
                  <dl key={i} className="answer-item">
                    <dt>{t.label}</dt>
                    {t.answerText && <dt style={{color: '#000'}}>{t.answerText}</dt>}
                    {
                      t.options.map((o: string, ix: number) => <dd key={ix}>{o}</dd>)
                    }
                  </dl>
                ))
              }
            </article>
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionAnamnesisFlexAnswersContent: React.FC<AnamnesisFlexAnswersProps> = (props: AnamnesisFlexAnswersProps): React.ReactElement => {
  const { section, survey, questions, isNewAnamnesis } = props;
  const [sectionData, setSectionData] = useState<any>();
  const [showSuicideRiskWarning, setShowSuicideRiskWarning] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (section) {
      const data = getSectionData(section, questions, survey, isNewAnamnesis);
      setSectionData(data)
    }
  }, [section, survey]);
  useEffect(() => {
    if (!sectionData || (isNewAnamnesis ? section.id !== 6 : section.id !== 7)) {
      setShowSuicideRiskWarning(false);
      return;
    }
    const suicideAnswer = sectionData.answers.find((item: any) => {
      return isNewAnamnesis ? item.key === '73' : item.key === '81';
    });
    setShowSuicideRiskWarning(!!(suicideAnswer && getAnswerRiskScore(suicideAnswer, isNewAnamnesis).actualScore));
  }, [sectionData, section])
  const sectionNoteProps = section && section.riskInformation ? { ...section.riskInformation.note, sectionId: section.id, showSuicideRiskWarning } : null;

  return (
    <section className="diagnostic_test_body">
      <Toogleable>
        {
          (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
            <div>
              {section && sectionData && <SectionTitle
                showRiskWarning={showSuicideRiskWarning}
                title={section.title}
                status={sectionData.status}
                date={sectionData.createdAt}
                riskInfo={section.riskInformation && section.riskInformation.note && section.riskInformation.note.intervals
                  ? { totalPoints: section.riskInformation.maxScore, actualPoints: sectionData.riskScore.numberGrade, textValue: sectionData.riskScore.textGrade }
                  : null}
                onClick={(): void => {
                  setDropdownId(dropdownId === 'survey' ? '' : 'survey');
                  if (!survey.viewingAttempts[section.id]) {
                    dispatch(setTestAsViewed({testId: survey._id, sectionId: section.id}));
                  }
                }} /> }
              {
                dropdownId && sectionNoteProps
                && <SectionNote { ...sectionNoteProps } />
              }
              {
                dropdownId
                && Object.keys(questions)
                  .filter((key: string, i: number) => questions[i].type < 200 && section.questionsSet.includes(parseInt(key, 10)))
                  .map((qa: string, index: number) =>
                    (<SectionAnswersContent
                      key={index}
                      isEditable={true}
                      questionKey={qa}
                      surveyId={survey._id}
                      question={questions[parseInt(qa, 10)]}
                      questionNumber={index + 1}
                      answer={sectionData.answers.find((a: Answer) => a.key === qa) as Answer}
                      isNewAnamnesis={isNewAnamnesis}
                    />))
              }
              {
                dropdownId &&
                <div className="flex align-center justify-end margin-me--top"
                  onClick={(): void => setDropdownId('')}
                >
                  <div className="search_btn search_btn--collapse">
                    <div className="icon-holder"><ArrowIcon className="search_filter-icon" /></div>
                    <span>{translate('Collapse')}</span>
                  </div>
                </div>
              }

            </div>
          )
        }
      </Toogleable>
    </section>
  )
}

const SectionInfo: React.FC<SectionInfoState> = (props: SectionInfoState) => {
  const { survey, config, isNewAnamnesis } = props;
  const _sections = [config.sections[config.sections.length - 1], ...config.sections.filter((sec: AnamnesisSectionData, i: number) => i !== (config.sections.length - 1))].sort((a, b) => a.id - b.id);
  // Move Alcohol next to ASRS
  _sections.splice(7, 0, _sections.pop() as AnamnesisSectionData);
  return (
    <div className="diagnostic_test_body">
      <section className="spoiler_box">
        <div>
          {
            survey.type === 'anamnesisFlex' || survey.type === 'anamnesisFlexNew'
              ? _sections.map((section: AnamnesisSectionData, i: number) =>
                <SectionAnamnesisFlexAnswersContent
                  key={i}
                  questions={config.questions}
                  survey={survey}
                  section={section}
                  isNewAnamnesis={isNewAnamnesis}
                />
              )
              : Object.keys(config.questions).map((a: string, index: number) =>
                (<SectionAnswersContent
                  key={index}
                  isEditable={true}
                  surveyId={survey._id}
                  questionKey={a}
                  question={config.questions[parseInt(a, 10)]}
                  questionNumber={index + 1}
                  answer={survey.answers[index]}
                  isNewAnamnesis={false}
                />))
          }
        </div>
      </section>
      <p style={{fontSize: '9px'}}>
          1. Kroenke K, Spitzer RL, Williams JB. The PHQ-9: validity of a brief depression severity measure. J Gen Intern Med. 2001 Sep;16(9):606-13.
          <br/>
          2. Bush K, Kivlahan DR, McDonell MB, Fihn SD, Bradley KA. The AUDIT alcohol consumption questions (AUDIT-C): an effective brief screening test for problem drinking. Ambulatory Care Quality Improvement Project (ACQUIP). Alcohol Use Disorders Identification Test.
          <br/>
          3. Saboonchi F, Perski A, Grossi G. Validation of Karolinska Exhaustion Scale: psychometric properties of a measure of exhaustion syndrome. Scand J Caring Sci. 2013 Dec;27(4):1010-7.
          <br/>
          4. Adler LA, Spencer T, Faraone SV, Kessler RC, Howes MJ, Biederman J, Secnik K. Validity of pilot Adult ADHD Self- Report Scale (ASRS) to Rate Adult ADHD symptoms.
          <br/>
          <a rel="noopener noreferrer" target="_blank" href="https://www.minnesmottagningen.se/forskning-evidens">www.gerassolutions.com</a>
          </p>
    </div>
  );
}

export default SectionInfo;
