/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContentVisibilityType {
    USER_ID = 'user-id',
    USER_ROLE = 'user-role',
    EVERYBODY = 'everybody',
    HIDDEN_FOR_INSTITUTION = 'hidden-for-institution',
}
