import React, { useEffect, useState } from "react"
import { getLanguages, translate } from "../../../../selectors/translations"
import useFormStateHandlerG2, { FormValidators } from "../../../../hooks/formStateHandlerG2"
import { useSelector } from "react-redux"
import { authUser } from "../../../../selectors/auth"
import './PatientCommonForm.scss'
import { ReactComponent as UserIcon } from '../../../../assets/img/icons/user-icon.svg'
import { PatientFormMode } from "../../constants/constants"
import PhoneInputWithMask from "../../../../components/PhoneInputWithMask"
import { getInstitutionsSelector } from "../../../../selectors/institutions"
import { isEqual } from "../../../../utils/isEqual"
import { MedStaffRole } from "../../../../sdk"

const VALIDATORS: FormValidators = {
    firstName: [
        (value) => value ? null : 'firstName'
    ],
    lastName: [
        (value) => value ? null : 'lastName'
    ],
    institution: [
        (value) => value ? null : 'institution'
    ],
    personalNumber: [
        (value) => {
            if (!value) {
                return 'invalidPNRLength';
            }
            const normalizedPNR = value ? value.toString().replace(/-/g, '').trim() : '';
            if (!/^[0-9]+$/.test(normalizedPNR)) {
                return 'invalidPNRFormat';
            }
            if (!normalizedPNR || normalizedPNR.length !== 12) {
                return 'invalidPNRLength';
            }
            return null;
        }
    ]
};

interface IProps {
    data: { [key: string]: any };
    mode: PatientFormMode;
    onFieldChange?: (data: any) => void;
    children?: React.ReactNode;
}

const PatientCommonForm: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { data, onFieldChange, children } = props;
    const [ previousData, setPreviousData ] = useState(data);
    const languages = useSelector(getLanguages);
    const user = useSelector(authUser);
    const {
        inputs,
        errors,
        handleInputChange,
        handleInputFocus,
        handleInputBlur,
        handleForceUpdate,
        setValueDirectly
    } = useFormStateHandlerG2(null, data, VALIDATORS, onFieldChange);

    useEffect(() => {
        if (
            inputs.personalNumber
            && inputs.personalNumber.length >= 8
            && !isNaN(parseInt(inputs.personalNumber, 10))
        ) {
            const userBirthDate = new Date(`${(inputs.personalNumber as string).substring(0, 4)}/${(inputs.personalNumber as string).substring(4, 6)}/${(inputs.personalNumber as string).substring(6, 8)}`)
            if (!isNaN(userBirthDate.valueOf())) {
                handleForceUpdate({birthDate: userBirthDate})
            }
        }
        const normalizedPNR = inputs.personalNumber ? inputs.personalNumber.toString().replace(/-/g, '').trim() : '';
        if (/^[0-9]+$/.test(normalizedPNR) && normalizedPNR.length === 12) {
            const gender = normalizedPNR[10] % 2 === 0 ? 'female' : 'male';
            handleForceUpdate({ gender })
        }
    }, [inputs.personalNumber]);

    useEffect(() => {
        if (isEqual(data, previousData)) {
            return;
        }
        handleForceUpdate(data, false);
        setPreviousData(data);
    }, [data]);
    const institutions = useSelector(getInstitutionsSelector);

    return (
        <div className="card-box patient-info">
            <div className="patient-common-form__header">
            <div className="box-title">
            <i className="icon-holder patients-title_icon-holder"><UserIcon color="#ffffff"/></i>
                <h3>{translate('headerCreatePatientSteps.step1')}</h3>
                </div>
            </div>
            <div className="card_inputs-box" style={{ marginBottom: 0}}>
                <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('firstName')}*</label>
                    <input type="text"
                           onChange={handleInputChange}
                           onFocus={handleInputFocus}
                           value={inputs.firstName}
                           name="firstName"
                           placeholder={translate('Type here...')}/>
                    {errors.includes('firstName') &&
                        <span className="login_form-error">{translate('Field must not be empty')}</span>}
                </div>
                <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('lastName')}*</label>
                    <input type="text"
                           onChange={handleInputChange}
                           onFocus={handleInputFocus}
                           value={inputs.lastName}
                           name="lastName"
                           placeholder={translate('Type here...')}/>
                    {errors.includes('lastName') &&
                        <span className="login_form-error">{translate('Field must not be empty')}</span>}
                </div>
                <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('personalNumber')}*</label>
                    <input type="text"
                           onChange={handleInputChange}
                           onBlur={handleInputBlur}
                           value={inputs.personalNumber}
                           name="personalNumber"
                           placeholder={translate('pnumberFormat')}/>
                    {errors.includes('invalidPNRFormat') && <span
                        className="login_form-error">{translate('errors.loginForm.invalidPNRFormat')}</span>}
                    {errors.includes('invalidPNRLength') && <span
                        className="login_form-error">{translate('errors.loginForm.invalidPNRLength')}</span>}
                </div>
                <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('phone')}</label>
                    <PhoneInputWithMask
                        inputValue={inputs.phoneNumber || ''}
                        institution={inputs.institution ?? user.institution}
                        onChange={(value: string) => setValueDirectly('phoneNumber', value)}
                    />
                </div>
                <div className="input-holder flex-item item-sm-6 item-md-4"><label>{translate('l')}</label>
                    <select onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            value={inputs.language}
                            name="language">
                        {languages.map((l: { key: string }, i: number) => (
                            <option key={i} value={l.key}>{translate(l.key)}</option>))}
                    </select>
                </div>
                {user.roles.includes(MedStaffRole.ADMIN) &&
                    <div className="input-holder flex-item item-sm-6 item-md-4">
                        <label>{translate('institution')}</label>
                            <select onChange={handleInputChange}
                                    value={inputs.institution}
                                    style={{ width: '100%' }}
                                    name="institution">
                                {institutions.map((i) => <option key={i.id}
                                                                                     value={i.id}>{i.name}</option>)}
                            </select>
                    </div>
                }

            </div>
            { children ?
                <div>
                    { children }
                </div>
                : null
            }
        </div>
    );
};

export default PatientCommonForm;
