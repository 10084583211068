/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProjectSettingName {
    LATEST_QUESTIONER_VERSIONS = 'latest-questioner-versions',
    APP_VERSION = 'app-version',
    UPDATE_APP_MODAL_VISIBLE = 'update-app-modal-visible',
}
