import { inputHasPoints, isQuestionScreen } from "../sdk/asserts";
import { QuestionerAnswersDTOOutput } from "../sdk";

export const riskLevelGrades = [
    {
        slug: "depression-section",
        introKey: 'anamnesis.risk-indicator.depression.description',
        descriptionKey: 'anamnesis.risk-indicator.depression.warning',
        intervals: {
            general: [
                {range: '0-4', titleKey: 'anamnesis.risk-indicator.minimal-or-none'},
                {range: '5-9', titleKey: 'anamnesis.risk-indicator.mild'},
                {range: '10-14', titleKey: 'anamnesis.risk-indicator.moderate'},
                {range: '15-19', titleKey: 'anamnesis.risk-indicator.moderate-severe'},
                {range: '20-27', titleKey: 'anamnesis.risk-indicator.severe'}
            ]
        }
    },
    {
        slug: "stress-questions-section",
        descriptionKey: 'stressNoteRisk',
        intervals: {
            general: [
                {range: '0-17', titleKey: 'low'},
                {range: '18-54', titleKey: 'high'}
            ]
        }
    },
    {
        slug: "concentration-section",
        descriptionKey: 'focusNoteRisk',
        intervals: {
            general: [
                {range: '0-11', titleKey: 'low'},
                {range: '12-24', titleKey: 'high'}
            ]
        }
    },
    {
        slug: "alcohol-drugs-section",
        descriptionKey: 'alcoholNoteRisk',
        intervals: {
            female: [
                { range: '0-2', titleKey: 'low' },
                { range: '3-5', titleKey: 'moderateRisk' },
                { range: '6-7', titleKey: 'high' },
                { range: '8-12', titleKey: 'severRisk' }
            ],
            male: [
                { range: '0-3', titleKey: 'low' },
                { range: '4-5', titleKey: 'moderateRisk' },
                { range: '6-7', titleKey: 'high' },
                { range: '8-12', titleKey: 'severRisk' }
            ]
        }
    },
];

export const riskInfoSectionSlugs = ["depression-section", "stress-questions-section", "concentration-section", "alcohol-drugs-section"];

export const extraInfoNotes = [
    {
        slug: "prescriptivedrugs-section",
        descriptionKey: 'medicinNoteRisk',
        intervals: null
    }
];

type getNewApiAnamnesisApiRiskInfoData = {
    anamnesis: QuestionerAnswersDTOOutput;
    sectionSlug: string;
    gender: string;
}
const getNewApiAnamnesisApiRiskInfo = (data: getNewApiAnamnesisApiRiskInfoData) => {

    const {anamnesis, sectionSlug, gender} = data;
    let riskLevelTitle = "";
    let descriptionKey = "";
    let introKey;
    let intervals: any = [];
    let suicideRisk = false

    if (!riskInfoSectionSlugs.includes(sectionSlug)) {
        return null
    }

    const section = anamnesis.questioner.sections.find(el => el.slug === sectionSlug)
    // @ts-ignore
    const screenIDs = section.screens.map(el => el.screen.id);

    const answers = anamnesis.answers.filter(el => screenIDs.includes(el.screen))

    // @ts-ignore
    const totalPoints = section.screens.reduce((acc, current) => {
        if (isQuestionScreen(current.screen) && inputHasPoints(current.screen.metadata.input)) {
            let points = current.screen.metadata.input.metadata.values.map(el => el.points)
            if (!points) {
                points = [0]
            }
            acc += Math.max(...points as number[])
        }
        return acc
    }, 0)

    // @ts-ignore
    const actualPoints = section.screens.reduce((acc, current) => {
        const correspondingAnswer = answers.find(el => el.screen === current.screen.id)

        if (correspondingAnswer && answers.length && isQuestionScreen(current.screen) && inputHasPoints(current.screen.metadata.input)) {
            // @ts-ignore
            const correspondingConfigValue = current.screen.metadata.input.metadata.values.find(el => el.value === correspondingAnswer.value)

            if (correspondingConfigValue && correspondingConfigValue.points) {
                acc += correspondingConfigValue.points
            }

            if (!suicideRisk) {
                const suicideRiskQuestion = current.screen.slug === "depression-suicide-question"
                // @ts-ignore
                suicideRisk = suicideRiskQuestion && correspondingConfigValue.points > 0
            }
        }
        return acc
    }, 0)


    const riskGrade = riskLevelGrades.find(el => el.slug === sectionSlug);

    if (riskGrade) {
        const isGeneralGrade = "general" in riskGrade.intervals;
        // @ts-ignore
        const riskLevelInterval = isGeneralGrade ? riskGrade.intervals.general : (riskGrade.intervals[gender] || riskGrade.intervals.male);
        riskLevelTitle = findInterval(actualPoints, riskLevelInterval)
        introKey = riskGrade.introKey
        descriptionKey = riskGrade.descriptionKey
        intervals = riskGrade.intervals
    }


    return {
        riskLevel: riskLevelTitle,
        intervals,
        introKey,
        descriptionKey,
        totalPoints,
        actualPoints,
        suicideRisk,
    }
}

function findInterval(expectedNumber: number, intervals: any[]) {
    for (const interval of intervals) {
        const [startStr, endStr] = interval.range.split('-');
        const start = parseInt(startStr, 10);
        const end = parseInt(endStr, 10);

        if (!isNaN(start) && !isNaN(end) && expectedNumber >= start && expectedNumber <= end) {
            return interval.titleKey;
        }
    }

    return ""; // If the expectedNumber is not within any range
}
export default getNewApiAnamnesisApiRiskInfo;
